import ApiConstants from "../constants/ApiConstants";
import http from "../http";

class PasswordPolicyService {
  static getPasswordPolicy() {
    return http
      .get(ApiConstants.URL.PASSWORD_POLICY.GET_PASSWORD_POLICY)
      .then((res) => {
        return Promise.resolve(res);
      });
  }

  static getPasswordPolicyByUsername(username) {
    return http
      .get(
        ApiConstants.URL.PASSWORD_POLICY.GET_PASSWORD_POLICY_BY_USERNAME(
          username
        )
      )
      .then((res) => {
        return Promise.resolve(res);
      });
  }

  static savePasswordPolicy(payload) {
    return http
      .post(ApiConstants.URL.PASSWORD_POLICY.UPDATE_PASSWORD_POLICY, payload)
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static expireExistingUsersPassword() {
    return http
      .post(ApiConstants.URL.PASSWORD_POLICY.EXPIRE_EXISTING_USERS_PASSWORD)
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }
}

export default PasswordPolicyService;
